<template>
  <div class="container">
    <strong>เพิ่มเมมเบอร์</strong>
    <div class="card">
      <div class="card-body">
        <NewAccountProfileForm
          v-if="input!==null"
          :data="input"
          :user="user"
          :invalid-keys="invalidKeys"
          role="Member"
          :minUsernameLength="2"
        />

        <div class="mt-3 pt-3" v-if="input!==null">
          <b-nav tabs>
            <b-nav-item :active="tabActive==='group'" @click="tabActive='group'">เปิด/ปิด กลุ่มหวย</b-nav-item>
            <b-nav-item :active="tabActive==='share'" @click="tabActive='share'">แบ่งหุ้น/เก็บของ</b-nav-item>
            <b-nav-item :active="tabActive==='limit'" @click="tabActive='limit'">ข้อมูลการแทง</b-nav-item>
            <b-nav-item :active="tabActive==='rate'" @click="tabActive='rate'">อัตราจ่าย</b-nav-item>
            <b-nav-form class="ml-auto">
              <b-form-select size="sm"
                v-model="copyMember"
                :options="memberOptions"
              ></b-form-select>
            </b-nav-form>
          </b-nav>

          <NewAccountSettingGroup :data="input.account.groups" v-if="tabActive==='group'" />
          <NewAccountSettingShare :data="input.account.groups" :ref-setting="refSetting" role="Member" v-if="tabActive==='share'" />
          <NewAccountSettingLimit :data="input.account.groups" :ref-setting="refSetting" v-if="tabActive==='limit'" />
          <NewAccountSettingRate :data="input.account.groups" :ref-setting="refSetting" v-if="tabActive==='rate'" />
        </div>
      </div>
      <div class="card-footer text-right">
        <button class="btn btn-primary" :disabled="isProcess" @click="createMember">{{isProcess ? 'กำลังบันทึก...' : 'เพิ่มเมมเบอร์'}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { passwordStrength } from 'check-password-strength'

import ManageAccountService from '@/services/ManageAccountService'
import NewAccountProfileForm from './components/NewAccountProfileForm'
import NewAccountSettingGroup from './components/NewAccountSettingGroup'
import NewAccountSettingShare from './components/NewAccountSettingShare'
import NewAccountSettingLimit from './components/NewAccountSettingLimit'
import NewAccountSettingRate from './components/NewAccountSettingRate'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

const invalidKeys = {
  username: {
    status: null,
    text: null
  },
  password: {
    status: null,
    text: null
  },
  firstName: {
    status: null,
    text: null
  }
}

export default {
  name: 'CreateMember',
  components: {
    NewAccountProfileForm,
    NewAccountSettingGroup,
    NewAccountSettingShare,
    NewAccountSettingLimit,
    NewAccountSettingRate
  },
  data() {
    return {
      user: {
        prepend: '',
        username: ''
      },
      input: null,
      refSetting: null,
      invalidKeys: JSON.parse(JSON.stringify(invalidKeys)),
      tabActive: 'group',
      isProcess: false,
      listMember: [],
      inputTemplate: null,
      copyMember: null
    }
  },
  computed: {
    memberOptions() {
      let options = [{
        text: 'คัดลอกจากเมมเบอร์',
        value: null
      }]

      this.listMember.forEach((m)=>{
        options.push({
          text: m.users[0].username,
          value: m._id
        })
      })

      return options
    },
    pwStrength() {
      return passwordStrength(this.input.user.password)
    },
    pwLength() {
      return this.pwStrength.length >= 8
    },
    pwLowercase() {
      return this.pwStrength.contains.includes('lowercase')
    },
    pwUppercase() {
      return this.pwStrength.contains.includes('uppercase')
    },
    pwNumber() {
      return this.pwStrength.contains.includes('number')
    },
    pwSymbol() {
      return this.pwStrength.contains.includes('symbol')
    },
    validPassword() {
      return this.pwLength && this.pwLowercase && this.pwUppercase && this.pwNumber && this.pwSymbol
    }
  },
  watch: {
    copyMember() {
      if(this.copyMember) {
        this.loadMember()
      }else{
        this.input.account.groups = JSON.parse(JSON.stringify(this.inputTemplate.account.groups))
      }
    }
  },
  methods: {
    loadTemplate() {
      ManageAccountService.getTemplate()
      .then((response)=>{
        if(response.success) {
          this.inputTemplate = JSON.parse(JSON.stringify(response.data.template))
          this.input = response.data.template
          this.refSetting = response.data.refSetting.groups
        }
      })
    },
    loadMembers() {
      ManageAccountService.getMembers()
      .then((response)=>{
        if(response.success) {
          this.listMember = response.data.accounts
        }
      })
    },
    loadMember() {
      ManageAccountService.getAccount(this.copyMember)
      .then((response)=>{
        if(response.success) {
          this.input.account.groups = response.data.account.groups
        }
      })
    },
    createMember() {

      let invalidCount = 0
      this.invalidKeys = JSON.parse(JSON.stringify(invalidKeys))

      /**
       * ตรวจสอบรูปแบบ username
       * @type {RegExp}
       */
      this.input.user.username = `${this.user.prepend}${this.user.username}`
      if(!this.input.user.username) {
        this.invalidKeys = {
          ...this.invalidKeys,
          username: {
            status: false,
            text: 'กรูณาระบุชื่อผู้ใช้'
          }
        }
        invalidCount++
      }else
      if(!/^([a-zA-Z0-9]{2,20})$/.test(this.input.user.username)) {
        this.invalidKeys = {
          ...this.invalidKeys,
          username: {
            status: false,
            text: 'ชื่อผู้ใช้ไม่ถูกต้อง'
          }
        }
        invalidCount++
      }

      /**
       * ตรวจสอบรหัสผ่าน
       */
      if(!this.input.user.password) {
        this.invalidKeys = {
          ...this.invalidKeys,
          password: {
            status: false,
            text: 'กรุณาระบุรหัสผ่าน'
          }
        }
        invalidCount++
      }else
      if(!this.validPassword) {
        this.invalidKeys = {
          ...this.invalidKeys,
          password: {
            status: false,
            text: 'รูปแบบรหัสผ่านไม่ถูกต้อง'
          }
        }
        invalidCount++
      }else
      if(!this.input.user.password || this.input.user.password.length < 6) {
        this.invalidKeys = {
          ...this.invalidKeys,
          password: {
            status: false,
            text: 'รหัสผ่านสั้นเกินไป'
          }
        }
        invalidCount++
      }

      /**
       * ตรวจสอบชื่อ
       */
      if(this.input.user.firstName.length < 1) {
        this.invalidKeys = {
          ...this.invalidKeys,
          firstName: {
            status: false,
            text: 'กรุณากรอกชื่อ'
          }
        }
        invalidCount++
      }

      if(invalidCount > 0) {
        Swal.fire({
          text: 'กรุณาตรวจสอบข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return
      }

      // this.invalidKeys = invalidKeys
      this.isProcess = true
      ManageAccountService.saveMember(this.input)
      .then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'สร้างเมมเบอร์เสร็จเรียบร้อย',
          })
          this.$router.push({name: 'ManageAccount'})
        }else{
          throw response
        }
      })
      .catch((e)=>{
        const errors = []
        if(e.success === false) {
          if(e?.errors) {
            e.errors.forEach((error)=>{
              this.findError(error)
              errors.push(error?.detail)
            })
          }
        }

        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'สร้างเมมเบอร์ไม่สำเร็จ กรุณาตรวจสอบข้อมูล',
          message: errors?.[0],
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    findError(error) {
      if(error?.source?.children) {
        error.source?.children.forEach((error)=>{
          this.findError(error)
        })
      }else
      if(error?.parameter){
        this.invalidKeys[error.parameter] = {
          status: false,
          text: error?.detail
        }
      }else
      if(error?.source){
        this.invalidKeys[error.source.parameter] = {
          status: false,
          text: error?.detail
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('reloadMarketGroups')
    this.$store.dispatch('reloadMarketPayrates')
    this.loadTemplate()
    this.loadMembers()
  }
}
</script>
